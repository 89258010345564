import { ObjectHelper } from './object-helper';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateHelper {
    public  static  DATE_PATTERN = 'DD.MM.YYYY';
    public  static  DATE_TIME_PATTERN = 'DD.MM.YYYY HH:mm:ss';

    public static trimToMidnight(d: Date): Date {
        if (d == null) {
            return;
        }
        d = new Date(d);
        d.setHours(0, 0, 0, 0);
        return d;
    }
    public static trimToEndOfDay(d: Date): Date {
        if (d == null) {
            return;
        }
        d = new Date(d);
        d.setHours(23, 59, 59, 999);
        return d;
    }
    public static addMinutesToDate(date: Date, minutes: number) {
        if (ObjectHelper.isEmptyObj(date) || ObjectHelper.isEmptyObj(minutes)) {
                return date;
        }
        return new Date(date.getTime() + minutes * 60000);
    }
    public static formatDateAsString(date: Date): string {
        return DateHelper.formatDate(date);

    }
    public  static formatDateTimeAsString(date: Date): string {
        return DateHelper.formatDateTime(date);
    }

    public static getDiffBetweenDates(dateStart: Date , dateEnd: Date, trimToMin: boolean= true): string {
        const distanceSeconds = DateHelper.getDistanceDateInSeconds(dateStart, dateEnd);
        let hours = distanceSeconds / 3600;
        let minutes = (distanceSeconds % 3600) / 60;
        let seconds = (distanceSeconds % 3600) % 60;
        let ret = '';
        hours = Math.floor( hours);
        if (hours > 0) {

            ret += (hours.toString().length < 2 ? ('0' + hours) : hours.toString()) + ' h ';
        }
        minutes = Math.floor( minutes);
        if (minutes > 0) {

            ret += (minutes.toString().length < 2 ? ('0' + minutes) : minutes.toString()) + ' m ';
        }
        if (!trimToMin) {
            seconds = Math.floor( seconds);
            if (seconds > 0) {

                ret += (seconds.toString().length < 2 ? ('0' + seconds) : seconds.toString()) + ' s';
            }
        }
        return ret;
    }

    public static checkOverlap(firstStart: Date , firstEnd: Date, secondStart: Date, secondEnd: Date): boolean {



        if (ObjectHelper.isEmptyObj(firstStart) || ObjectHelper.isEmptyObj(firstEnd) ||
        ObjectHelper.isEmptyObj(secondStart) || ObjectHelper.isEmptyObj(secondEnd)
        ) {

            return false;
        }


        if (firstStart.getTime() < secondStart.getTime() && firstEnd.getTime() > secondStart.getTime()) {
                return true;
        }
        if (firstStart.getTime() < secondEnd.getTime() && firstEnd.getTime() > secondEnd.getTime()) {
            return true;
        }

        if (secondStart.getTime() < firstStart.getTime() && secondEnd.getTime() > firstStart.getTime()) {
            return true;
        }
        if (secondStart.getTime() < firstEnd.getTime() && secondEnd.getTime() > firstEnd.getTime()) {
            return true;
        }
        return false;

    }
    public static getDistanceDateInMilliseconds(startDate: Date, endDate: Date): number {
        if (ObjectHelper.isEmptyObj(startDate) || ObjectHelper.isEmptyObj(endDate)) {
            return 0;
        }
        return endDate.getTime() - startDate.getTime();
    }
    public static getDistanceDateInSeconds(startDate: Date, endDate: Date): number {
        return DateHelper.getDistanceDateInMilliseconds(startDate, endDate) / 1000;
    }
    public static getDistanceDateInMinutes(startDate: Date, endDate: Date): number {
        return DateHelper.getDistanceDateInSeconds(startDate, endDate) / 60;
    }
    public static isSameDay(startDate: Date, endDate: Date) {
        if (ObjectHelper.isEmptyObj(startDate) && ObjectHelper.isEmptyObj(endDate)) {
            return true;
        }
        if (!ObjectHelper.isEmptyObj(startDate) && ObjectHelper.isEmptyObj(endDate)) {
            return true;
        }
        if (ObjectHelper.isEmptyObj(startDate) && !ObjectHelper.isEmptyObj(endDate)) {
            return true;
        }
        return  startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getDate() === endDate.getDate();
    }
    public static toIsoSetTimezoneToUTC(d: Date): string {

        if (ObjectHelper.isEmptyObj(d)) {
            return null;
        }
        const z = n => (n < 10 ? '0' : '') + n;
        let off = 0; // d.getTimezoneOffset();
        const sign = off < 0 ? '+' : '-';
        off = Math.abs(off);

        return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' +
            z(d.getDate()) + 'T' + z(d.getHours()) + ':' + z(d.getMinutes()) +
            // tslint:disable-next-line:no-bitwise
            ':' + z(d.getSeconds()) + sign + z(off / 60 | 0) + z(off % 60);


    }
    public static formatDate(date: Date): string {
        if (date == null || date === undefined) {
            return null;
        }
        return moment(date).format(DateHelper.DATE_PATTERN);
    }
    public static formatDateTime(date: Date): string {
        if (date == null || date === undefined) {
            return null;
        }
        return moment(date).format(DateHelper.DATE_TIME_PATTERN);
    }
    public static setTimezoneUTC(date: Date): Date {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(),
         date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    public static convertDateToUTC(date): Date {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(),
         date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    public static extractHoursMinutesFromDate(date: Date): string {
        if (ObjectHelper.isEmptyObj(date)) {
            return null;
        }
        const hour = date.getHours() < 10 ? ('0' + date.getHours()) : (date.getHours() + '');
        const minute = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : (date.getMinutes() + '');
        return hour + ':' + minute;
    }
    public static getMinutesFromString(data: string) {
        if (ObjectHelper.isEmptyStr(data)) {
            return null;
        }
        if (data.indexOf(':') < 0) {
            return null;
        }
        data = data.trim();
        return data.split(':')[1];
    }

    public static getHoursFromString(data: string) {
        if (ObjectHelper.isEmptyStr(data)) {
            return null;
        }
        if (data.indexOf(':') < 0) {
            return null;
        }
        data = data.trim();
        return data.split(':')[0];
    }
    public static isValidDateTime(userdate): boolean {
        if (ObjectHelper.isEmptyObj(userdate)) {
            return true;
        }
        try {
            if (moment(userdate, DateHelper.DATE_TIME_PATTERN, true).isValid()) {

                return true;
            }

        } catch (e) {
            return false;
        }
        return false;
    }
    public static isValidDate(userdate): boolean {
        if (ObjectHelper.isEmptyObj(userdate)) {
            return true;
        }
        try {
            if (moment(userdate, DateHelper.DATE_PATTERN, true).isValid()) {

                return true;
            }

        } catch (e) {
            return false;
        }
        return false;
    }
    public static getDateTimeFromString(userDate: string, excludeSeconds: boolean = true): Date {

        if (ObjectHelper.isEmptyStr(userDate)) {
            return null;
        }
        if (!this.isValidDateTime(userDate)) {
            return null;
        }
        let ret: Date = moment(userDate, DateHelper.DATE_TIME_PATTERN).toDate();
        if (excludeSeconds) {
            ret = new Date(ret.getFullYear(), ret.getMonth(), ret.getDate(), ret.getHours(), ret.getMinutes(), 0);

        }
        return ret;
    }
    public static getDateFromString(userDate: string): Date {

        if (ObjectHelper.isEmptyStr(userDate)) {
            return null;
        }

        if (DateHelper.isValidDateTime(userDate)) {
            const ret: Date = DateHelper.getDateTimeFromString(userDate, false);
            if (ObjectHelper.isEmptyObj(ret)) {
                return null;
            }
            return new Date(Date.UTC(ret.getFullYear(), ret.getMonth(), ret.getDate(), 0, 0, 0));
        }
        if (DateHelper.isValidDate(userDate)) {
            const ret: Date = moment(userDate, DateHelper.DATE_PATTERN).toDate();
            return new Date(Date.UTC(ret.getFullYear(), ret.getMonth(), ret.getDate(), 0, 0, 0));

        }
        return null;



    }
    public static getHourMinutesToMinutes(hourMinutes: string): number {

        if (ObjectHelper.isEmptyObj(hourMinutes)) {
            return 0;
        }
        if (hourMinutes.indexOf(':') < 0) {
            return 0;
        }
        const hoursMinutesList = hourMinutes.split(':');
        if (!ObjectHelper.isNumber(hoursMinutesList[0])) {
            return 0;
        }
        if (!ObjectHelper.isNumber(hoursMinutesList[1])) {
            return 0;
        }

        return Number.parseInt(hoursMinutesList[0], 10) * 60 + Number.parseInt(hoursMinutesList[1], 10);
    }
    public static getDateInISOString(date: Date): string {
        if (ObjectHelper.isEmptyObj(date)) {
            return null;
        }
        return date.toISOString();
    }
    public fixMaxIfEmpty(date: string): Date {
        if (ObjectHelper.isEmptyObj(date)) {
            return new Date(9999, 0);
        }
        return DateHelper.trimToMidnight(DateHelper.getDateFromString(date));
    }
    public getLastDateOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    public getFirstDayOfMonth(date: Date): Date {

        return new Date(date.getFullYear(), date.getMonth(), 1);

    }
    public convertStringDateToIsoString(date: string) {
        if (ObjectHelper.isEmptyStr(date)) {
            return null;
        }
        // console.log("DATE "+ date+ " ISO= "+DateHelper.getDateInISOString(this.getDateFromString(date)));
        return DateHelper.getDateInISOString(DateHelper.getDateFromString(date));

    }
    public convertStringDateTimeToIsoString(date: string, excludeSeconds: boolean = true) {
        if (ObjectHelper.isEmptyStr(date)) {
            return null;
        }
        return DateHelper.getDateInISOString(DateHelper.getDateTimeFromString(date, excludeSeconds));

    }
    public getMonthName(date: Date): string {
        if (ObjectHelper.isEmptyObj(date)) {
            return '';
        }


        return moment(date).startOf('month').format('MMMM');
    }



    public getDateFromIsoString(userDate: string): Date {
        return null;
    }



    constructor() {

    }

}
