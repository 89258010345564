import { isNgTemplate } from '@angular/compiler';
import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MatExpansionPanelHeader, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { ObjectHelper } from '../helpers/object-helper';
import { EmployeesModel } from '../models/employees.model';
import { TableModel } from '../models/table.model';
import { ServerApi } from '../server.service';
import { DateHelper } from './../helpers/date-helper';
import { ResourceWait } from './../helpers/resource-wait';

@Component({
  selector: 'app-editor-modal',
  templateUrl: './editor-modal.component.html',
  styleUrls: ['./editor-modal.component.css'],
  providers: [ResourceWait]
})
export class EditorModalComponent implements OnDestroy {

  @ViewChild('frm') public frm: NgForm;
  @ViewChild('frmRef') frmRef: ElementRef;
  public subscriptions: Subscription[] = [];
  public employees: EmployeesModel[];

  public zajednickiTrosakMode = true;
  public forced = false;
  public dummy = true;


  constructor(
    public server: ServerApi,
    public wait: ResourceWait,
    public dialogRef: MatDialogRef<EditorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TableModel) {
    this.data = ObjectHelper.clone(data);
    this.server.getEmployees().subscribe((employees: EmployeesModel[]) => {
      this.employees = employees;
    });
  }
  zajednickiTrosakChange() {
    this.subscriptions.push(this.wait.waitForBoolean(() => this.employees != null && this.employees.length > 0).subscribe(x => {
      if (this.zajednickiTrosakMode) {

        this.data.zajednickiTrosak = 0;
        this.employees.forEach((item: EmployeesModel) => {
          this.data['suma' + item.nameFirstLetterUppercase] = 1;
        });

      } else {
        this.data.zajednickiTrosak = null;
        this.employees.forEach((item: EmployeesModel) => {
          this.data['suma' + item.nameFirstLetterUppercase] = null;
          this.data[item.name] = 0;
        });

      }

      this.populateFrom(true);
    }));
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.populateFrom(false);
  }
  public getNumberValue(name: string) {
    if (ObjectHelper.isEmptyObj(this.frm.value[name])) {
      return 0;
    }
    if (!ObjectHelper.isNumber(this.frm.value[name])) {
      return 0;
    }
    return Number.parseFloat(this.frm.value[name]);

  }
  public clearCommonExpense(employee: EmployeesModel) {
    if (this.zajednickiTrosakMode) {
      return;
    }
    this.subscriptions.push(this.wait.waitForBoolean(() => this.employees != null && this.employees.length > 0).subscribe(x => {
      this.frm.controls[employee.name].setValue(0);

    }));
  }
  public clearNotCommonExpense(employee: EmployeesModel) {

    if (!this.zajednickiTrosakMode) {
      return;
    }
    this.subscriptions.push(this.wait.waitForBoolean(() => this.employees != null && this.employees.length > 0).subscribe(x => {
      this.frm.controls['suma' + employee.nameFirstLetterUppercase].setValue(0);
      setTimeout(() => {
        this.updatePonderValues();
      });

    }));
  }
  public updatePonderValues() {

    if (!this.zajednickiTrosakMode) {
      return;
    }
    this.subscriptions.push(this.wait.waitForBoolean(() => this.employees != null && this.employees.length > 0).subscribe(x => {


      if (!ObjectHelper.isEmptyStr(this.validatePonders())) {
        this.employees.forEach((emp) => {
          this.data[emp.name] = 0;
        });
        return;
      }
      const zajednickiTrosak = this.getNumberValue('zajednickiTrosak');
      let totalSum = 0;
      const eachSum: number[] = [];
      this.employees.forEach((employee) => {
        const currentSum = this.getNumberValue('suma' + employee.nameFirstLetterUppercase);
        eachSum.push(currentSum);
        totalSum += currentSum;
      });
      for (let i = 0; i < this.employees.length; i++) {
        this.data[this.employees[i].name] = Number.parseFloat((zajednickiTrosak * eachSum[i] / totalSum).toFixed(2));
      }
    }));
  }



  populateFrom(ignorePopulateTrosak) {
    //this.frm.controls[this.employees.name[this.employees.name.length - 1]] != null
    this.subscriptions.push(this.wait.waitForBoolean(() => this.employees != null && this.employees.length > 0
    ).subscribe(x => {
      console.log('POPULATE FRM')
      if (!ignorePopulateTrosak) {
        this.zajednickiTrosakMode = !ObjectHelper.isEmptyObj(this.data.zajednickiTrosak);
      }
      setTimeout(() => {
        if (!ObjectHelper.isEmptyObj(this.frm.controls['datum'])) {
          this.frm.controls['datum'].setValue(this.data.datum);
        }
        if (!ObjectHelper.isEmptyObj(this.frm.controls['stavkaOpis'])) {
          this.frm.controls['stavkaOpis'].setValue(this.data.stavkaOpis);
        }
        let currentName: string;
        let nameFirstLetterUppercase: string;
        for (let i = 0; i < this.employees.length; i++) {
          currentName = this.employees[i].name;
          nameFirstLetterUppercase = this.employees[i].nameFirstLetterUppercase;
          if (!ObjectHelper.isEmptyObj(this.frm.controls[currentName])) {
            this.frm.controls[currentName].setValue(this.data[currentName]);
          }
          if (!ObjectHelper.isEmptyObj(this.frm.controls['suma' + nameFirstLetterUppercase])) {
            this.frm.controls['suma' + nameFirstLetterUppercase].setValue(this.data['suma' + nameFirstLetterUppercase]);
          }

        }
        if (!ObjectHelper.isEmptyObj(this.frm.controls['zajednickiTrosak'])) {
          this.frm.controls['zajednickiTrosak'].setValue(this.data.zajednickiTrosak);
        }
        if (!ObjectHelper.isEmptyObj(this.frm.controls['komentar'])) {
          this.frm.controls['komentar'].setValue(this.data.komentar);
        }
        if (!ObjectHelper.isEmptyObj(this.frm.controls['remoteId'])) {
          this.frm.controls['remoteId'].setValue(this.data.remoteId);
        }
        if (!ObjectHelper.isEmptyObj(this.frm.controls['checked'])) {
          this.frm.controls['checked'].setValue(this.data.checked);
        }


      });
    }));



  }


  public isRequired(name: string): string {
    if (ObjectHelper.isEmptyObj(this.frm.value[name])) {
      return 'Polje je obavezno';
    }
    return '';
  }
  public isValidDate(name: string): string {
    if (ObjectHelper.isEmptyObj(this.frm.value[name])) {
      return '';
    }

    if (!DateHelper.isValidDate(this.frm.value[name])) {
      if (!DateHelper.isValidDate(new Date(this.frm.value[name]))) {
        return 'Polje mora biti datum';
      }
    }
    return '';
  }
  public isValidNumber(name: string): string {
    if (ObjectHelper.isEmptyObj(this.frm.value[name])) {
      return '';
    }
    if (!ObjectHelper.isNumber(this.frm.value[name])) {
      return 'Polje mora biti broj';
    }
    return '';
  }
  public validatePonders() {
    let currentName = '';
    let nameFirstLetterUppercase = '';
    let valid = false;
    for (let i = 0; i < this.employees.length; i++) {
      currentName = this.employees[i].name;
      nameFirstLetterUppercase = this.employees[i].nameFirstLetterUppercase;

      if (Number.parseFloat(this.frm.value['suma' + nameFirstLetterUppercase]) !== 0) {
        console.log('POND ' + nameFirstLetterUppercase + 'SU ' + Number.parseFloat(this.frm.value['suma' + nameFirstLetterUppercase]))
        valid = true;
      }
    }
    console.log('POND VALID' + valid)

    if (!valid) {
      return 'Svi ponderi ne mogu biti 0';
    } else {
      return '';
    }
  }


  public getInvalidMessage(name: string): string {
    if (!this.forced) {
      return '';
    }
    console.log('VALIDATE ' + name)

    if (name === 'datum') {
      let error: string = this.isRequired(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
      error = this.isValidDate(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
    }
    if (name === 'stavkaOpis') {
      const error: string = this.isRequired(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
    }
    if (this.employees.findIndex(x => x.name === name) >= 0) {
      let error: string = this.isRequired(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
      error = this.isValidNumber(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
    }
    if (this.employees.findIndex(x => ('suma' + x.nameFirstLetterUppercase) === name) >= 0) {
      let error: string = this.isRequired(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
      error = this.isValidNumber(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
      error = this.validatePonders();
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
    }


    if (name === 'zajednickiTrosak') {
      let error: string = this.isRequired(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
      error = this.isValidNumber(name);
      if (!ObjectHelper.isEmptyObj(error)) {
        return error;
      }
    }
    return '';
  }
  public isValid(): boolean {
    const keys: any[] = Object.keys(this.frm.value);
    for (let i = 0; i < keys.length; i++) {
      if (!ObjectHelper.isEmptyObj(this.getInvalidMessage(keys[i]))) {
        return false;
      }
    }

    return true;
  }

  save(): void {
    this.forced = true;
    if (this.isValid()) {
      this.forced = false;
      const keys: any[] = Object.keys(this.frm.value);
      for (let i = 0; i < keys.length; i++) {
        this.data[keys[i]] = this.frm.value[keys[i]];
      }
      this.data.datum = DateHelper.setTimezoneUTC(new Date(this.data['datum']));
      this.data.checked = ObjectHelper.ifEmpty(this.data.checked, false);
      this.updatePonderValues();
      this.dialogRef.close(this.data);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }
}
