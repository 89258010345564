import { OnDestroy, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ObjectHelper } from './object-helper';
import { ResourceWatcher } from './resource-watcher';

@Injectable()
export class ResourceWait implements OnDestroy {
    private listeners = new Map<string, ResourceWatcher>();
    public onDestroyStated = false;


    public waitForNotNull(condition: (() => any[] | any),
        conditionFailedCallback: (() => void) = null
        , timeout = 15000): Observable<boolean> {

        return this.waitForBoolean(() => {
            let element = condition();
            if (!ObjectHelper.isArray(element)) {
                element = [element];
            }
            const array: any[] = element;
            for (let i = 0; i < ObjectHelper.getListSize(array); i++) {
                if (ObjectHelper.isEmptyObj(array[i])) {
                    return false;
                }
            }
            return true;

        }, conditionFailedCallback, timeout);
    }

    public waitForBoolean(condition: (() => boolean),
        conditionFailedCallback: (() => void)  = null, timeout = 15000): Observable<boolean> {
        const rw = new ResourceWatcher(condition, conditionFailedCallback, timeout, (id: string) => {
            this.removeResourceWatcherById(id);
        });
        this.listeners.set(rw.listenerId, rw);

        setTimeout(() => {
            rw.startWorking();
        });

        return rw.observable;
    }

    public removeResourceWatcherById(id: string) {

        if (this.onDestroyStated) {
            return;
        }

        if (this.listeners.has(id)) {
            this.listeners.delete(id);
        }
    }

    ngOnDestroy(): void {
        this.onDestroyStated = true;
        this.listeners.forEach((value: ResourceWatcher, key: string) => {
            value.forceReject();
        });
    }

}
