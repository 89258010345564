import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { forkJoin, Subscription } from 'rxjs';
import { EditorModalComponent } from '../editor-modal/editor-modal.component';
import { ObjectHelper } from '../helpers/object-helper';
import { SummaryModel } from '../models/summary.model';
import { TableModel } from '../models/table.model';
import { ServerApi } from '../server.service';
import { DateHelper } from './../helpers/date-helper';
import { ResourceWait } from './../helpers/resource-wait';
import { EmployeesModel } from './../models/employees.model';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  providers: [ResourceWait]
})
export class EditorComponent implements OnInit, AfterViewInit, OnDestroy {

  public subscriptions: Subscription[] = [];
  public dateFrom: Date;
  public dateUntil: Date;
  public employees: EmployeesModel[];
  public summary: SummaryModel;
  public working = false;
  public orderByInsertion = false;
  @ViewChild('frm') public frm: NgForm;
  @ViewChild('frmRef') frmRef: ElementRef;
  @ViewChild('table', {read: ElementRef}) table: ElementRef;
  public tableList: MatTableDataSource<TableModel>;
  public displayedColumns: string[] = [];
  public skipSumFiltered = true;


  constructor(public server: ServerApi,
    public wait: ResourceWait,
    public dateHelper: DateHelper, public dialog: MatDialog) {
    const date = new Date();
    this.dateUntil = dateHelper.getLastDateOfMonth(new Date(Date.UTC(date.getFullYear() + 1, date.getMonth(), date.getDate(), 0, 0, 0)));
    this.dateFrom = dateHelper.getFirstDayOfMonth(new Date(Date.UTC(date.getFullYear(), date.getMonth() - 2, date.getDate(), 0, 0, 0)));
    this.summary = new SummaryModel();

    this.server.getEmployees().subscribe(employees => {
      this.employees = employees;

      this.displayedColumns = ['index', 'action', 'date', 'stavkaOpis',
        ...this.employees.map(e => e.name)
        , 'zajednickiTrosak',
        ...this.employees.map(e => e.nameFirstLetterUppercase).map(x => 'suma' + x), 'komentar'];


      this.loadData();

    });


  }
  skipSumChange() {
    setTimeout(() => {
      this.applyFilter(this.tableList.filter);
    });
  }


  openDialog(tableModel: TableModel): void {
    const dialogRef = this.dialog.open(EditorModalComponent, {
      maxWidth: '600px',
      width: '98vw',
      height: '98vh',
      data: tableModel
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!ObjectHelper.isEmptyObj(result)) {
        this.server.saveObject(result).subscribe(d => {
          this.search();
        });
      }


    });
  }
  exportAsExcel()
  {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);//converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Exported');

    /* save to file */
    XLSX.writeFile(wb, 'Export.xlsx');


  }

  applyFilter(filterValue: string) {
    this.tableList.filter = filterValue.trim().toLowerCase();
    const toSum = this.tableList.filteredData;

    if (!ObjectHelper.isEmptyObj(toSum)) {
        this.employees.forEach(x => {
          x.sumFiltered = 0;
          toSum.forEach(data => {
          if (!this.skipSumFiltered || data.checked) {
            x.sumFiltered = x.sumFiltered + Number.parseFloat(data[x.name]);
          }
        });
      });

    }
  }
  delete(element: TableModel) {
    if (confirm('Are you sure you want to delete ?')) {
      this.server.dropObject(element.id).subscribe(data => {
        this.loadData();
      });
    }
  }
  search() {
    this.dateUntil = this.frm.value['dateUntil'];
    this.dateFrom = this.frm.value['dateFrom'];
    this.orderByInsertion = this.frm.value['orderByInsertion'];
    this.loadData();
  }
  add() {
    const tableModel = new TableModel();
    tableModel.datum = new Date();



    let currentName = '';
    let currentNameFirstLetterUppercase = '';
    for (let i = 0; i < this.employees.length; i++) {
      currentName = this.employees[i].name;
      currentNameFirstLetterUppercase = this.employees[i].nameFirstLetterUppercase;
      tableModel[currentName] = 0;
      tableModel['suma' + currentNameFirstLetterUppercase] = 1;
    }

    tableModel.zajednickiTrosak = 0;
    this.openDialog(tableModel);

  }
  openDetails(tableModel: TableModel, clone: boolean = false) {
    if (clone) {
      tableModel = ObjectHelper.clone(tableModel);
      tableModel.id = null;
      tableModel.remoteId = null;
      tableModel.datum = new Date();
    }
    this.openDialog(tableModel);
  }

  ngOnInit() {

  }
  public loadData(): void {
    this.working = true;
    forkJoin([this.server.getTable(this.dateFrom, this.dateUntil, this.orderByInsertion),

    this.server.getSummary()]).subscribe(results => {

      this.tableList = new MatTableDataSource(results[0] as TableModel[]);
      this.summary = results[1];
      this.applyFilter(this.tableList.filter);
      this.working = false;
    });


  }
  ngAfterViewInit(): void {

    this.subscriptions.push(this.wait.waitForNotNull(() =>
      [this.employees, this.frm,

      ]).subscribe(x => {
        this.subscriptions.push(this.wait.waitForNotNull(() => [
          this.frm.controls,
          this.frm.controls['dateFrom'],
          this.frm.controls['dateUntil']
        ]).subscribe(() => {
          this.frm.controls['dateFrom'].setValue(this.dateFrom);
          this.frm.controls['dateUntil'].setValue(this.dateUntil);
        }));

      }));

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }
}
