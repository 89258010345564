import { ObjectHelper } from './object-helper';
import { Observable, Subscriber } from 'rxjs';
export class ResourceWatcher {

    public listenerId: string;
    public observable: Observable<boolean> = null;
    public observer: Subscriber<boolean> = null;
    private abort: boolean;
    public intervalRef: any;
    public started: Date;
    public stack: Error;
    constructor(public condition: (() => boolean),
        public conditionFailedCallback: (() => void),
        public timeoutMs: number,
        public destroyed: ((id: string) => void)) {
        this.listenerId = ObjectHelper.getNewIdAsGuid();
        this.started = new Date();
        this.stack = new Error();
        this.observable = new Observable((observer: Subscriber<boolean>) => {
            this.observer = observer;
        });
    }

    public startWorking() {
        if (!this.doStep()) {
            this.intervalRef = setInterval(() => {
                this.doStep();
            }, 1);
        } else {
            this.cleanUp();
        }
    }
    public forceReject() {
        this.abort = true;
        this.doClose();
        this.cleanUp();
    }
    private doClose() {
        if (!ObjectHelper.isEmptyObj(this.observer)) {
            this.observer.complete();
        }
    }
    private doNext() {
        if (!ObjectHelper.isEmptyObj(this.observer)) {
            this.observer.next(true);
        }
    }
    private isTimeout(): boolean {
        if (ObjectHelper.isEmptyObj(this.timeoutMs)) {
            return false;
        }
        return (new Date()).getTime() - this.started.getTime() > this.timeoutMs;

    }

    public doStep() {
        if (this.abort) {
            this.doClose();
            this.cleanUp();
            return true;
        }
        if (this.isTimeout()) {
            this.doClose();
            this.cleanUp();
            console.error('TIMEOUT FOR ');
            return true;
        }
        if (this.condition()) {
            this.doNext();
            this.cleanUp();
            return true;
        }
        if (!ObjectHelper.isEmptyObj(this.conditionFailedCallback)) {
            this.conditionFailedCallback();

        }
        return false;

    }
    private cleanUp(): void {
        if (!ObjectHelper.isEmptyObj(this.intervalRef)) {
            clearInterval(this.intervalRef);
            this.intervalRef = null;
        }
        this.destroyed(this.listenerId);
    }
}
