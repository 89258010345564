import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { EditorComponent } from './editor/editor.component';

const appRoutes: Routes = [
    {
        path: '',
        component: EditorComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'editor',
                component: EditorComponent,
                canActivate: [AuthGuard],
                runGuardsAndResolvers: 'always',
            }
        ]
    },
    { path: 'login', component: LoginComponent, runGuardsAndResolvers: 'always', },
    { path: '**', redirectTo: '', }
];
export const AppRoutingModule = RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' });
