import { NativeDateAdapter } from "@angular/material";
import { Injectable } from "@angular/core";
import { DateHelper } from "./helpers/date-helper";
import { ObjectHelper } from "./helpers/object-helper";

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'short'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
 };
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {


    format(date: Date, displayFormat: Object): string {

        return DateHelper.formatDate(date);
    }
    parse(value: any): Date | null {
 
        if ((typeof value === 'string') ) {
            if (ObjectHelper.isEmptyStr(value)) {
                return null;

            }
            const date: Date = DateHelper.getDateFromString(value.toString());
            if (ObjectHelper.isEmptyObj(date)) {
                return new Date(-1);
            }
            return date;
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
      }
 }
