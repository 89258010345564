import { map } from 'rxjs/operators';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  CanActivate
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServerApi } from './server.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public server: ServerApi) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      if ( !this.server.isLoggedIn()) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return of(false);
      }
    return of(true);
  }


}

