export class ObjectHelper {
    public static getNewIdAsGuid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    public static clone<T>(object: T): T {
        if (object == null || object === undefined) {
            return object;
        }
        return JSON.parse(JSON.stringify(object));
    }
    public static isArray(value: any) {
        return value && typeof value === 'object' && value.constructor === Array;
    }
    public static trimStringIfRequired(val: string): string {
        if (ObjectHelper.isEmptyObj(val)) {
            return val;
        }
        val = val + '';
        return val.trim();
    }
    public static toCamel(o: any, firstLetterUpercase: boolean): any {
        let newO, origKey, newKey, value;
        if (o instanceof Array) {
            // tslint:disable-next-line:no-shadowed-variable
            return o.map(function (value) {
                if (typeof value === 'object') {
                    value = ObjectHelper.toCamel(value, firstLetterUpercase);
                }
                return value;
            });
        } else {
            newO = {};
            for (origKey in o) {
                if (o != null && o.hasOwnProperty(origKey)) {
                    // tslint:disable-next-line:max-line-length
                    newKey = ((firstLetterUpercase ? origKey.charAt(0).toUpperCase() : origKey.charAt(0).toLowerCase()) + origKey.slice(1) || origKey).toString();
                    value = o[origKey];
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = ObjectHelper.toCamel(value, firstLetterUpercase);
                    }
                    newO[newKey] = value;
                }
            }
            return newO;
        }
    }

    public static isNumber(value: string | number): boolean {
        return !isNaN(Number(value.toString()));
    }

    public static formatString(str: string, args: any): string {
        if (str == null) {
            return null;
        }
        if (args == null) {
            args = '';
        }
        if (!(args instanceof Array)) {
            args = [args];
        }
        // tslint:disable-next-line:radix
        return str.replace(/{[0-9]}/g, (matched) => args[parseInt(matched.replace(/[{}]/g, ''))]);
    }
    public static ifEmpty(data: any, emptyVal: any): any {
        if (ObjectHelper.isEmptyObj(data)) {
            return emptyVal;
        } else {
            return data;
        }
    }
    public static trim(str: string): string {
        if (str == null) {
            return str;
        }
        str = str.toString();
        return str.replace(/^\s+|\s+$/g, '');
        // return str;
    }
    public static getListSize(list: any[]) {
        if (ObjectHelper.isEmptyList(list)) {
            return 0;
        }
        return list.length;
    }

    public static convertToString(obj: any) {
        if (ObjectHelper.isEmptyObj(obj)) {
            return '';
        }
        return (obj + '').trim();
    }
    public static isEmptyStr(str: string): boolean {
        return str === undefined || str == null || this.trim(str) === '';
    }
    public static isEmptyNumber(no: number): boolean {
        return no === undefined || no == null || isNaN(no);
    }
    public static isStringEqual(s1: string, s2: string, ignoreCase: boolean = false): boolean {
        if (ObjectHelper.isEmptyObj(s1) && ObjectHelper.isEmptyObj(s2)) {
            return true;
        }
        if (!ObjectHelper.isEmptyObj(s1) && ObjectHelper.isEmptyObj(s2)) {
            return false;
        }
        if (ObjectHelper.isEmptyObj(s1) && !ObjectHelper.isEmptyObj(s2)) {
            return false;
        }
        if (ignoreCase) {
            s1 = s1.toLowerCase();
            s2 = s2.toLowerCase();
        }
        return s1.trim() === s2.trim();

    }

    public static isEmptyToNull(data: any) {
        if (data == null || data === undefined) {
            return null;
        }
        if (typeof data === 'string' && ObjectHelper.isEmptyStr(data)) {
            return null;
        }
        return data;
    }

    public static isEmptyList(list: any[]): boolean {
        return list == null || list === undefined || list.length === 0;
    }
    public static replaceAllString(original: string, src: string, dest: string): string {
        if (ObjectHelper.isEmptyStr(original)) {
            return original;
        }
        if (ObjectHelper.isEmptyStr(src)) {
            return original;
        }
        if (ObjectHelper.isEmptyStr(dest)) {
            dest = '';
        }
        while (original.indexOf(src) >= 0) {
            original = original.replace(src, dest);
        }
        return original;
    }


    public static isEmptyObj(obj: any): boolean {
        if (obj === null || obj === undefined) {
            return true;
        }
        if (typeof obj === 'number') {
            return ObjectHelper.isEmptyNumber(obj);
        }
        if (typeof obj === 'string') {
            return ObjectHelper.isEmptyStr(obj);
        }
        if (obj instanceof Array) {
            return ObjectHelper.isEmptyList(obj);
        }
        return false;
    }

}
