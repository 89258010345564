import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ServerApi } from '../server.service';
import { NgForm } from '@angular/forms';
import { ObjectHelper } from '../helpers/object-helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('frm') public frm: NgForm;
  @ViewChild('frmRef') frmRef: ElementRef;
  constructor(private snackBar: MatSnackBar, private server: ServerApi, private router: Router) { }

public showError(): void {
  this.snackBar.open('Login failed', 'Failed', {
    duration: 2000,
  });
}
  public doLogin() {

    const username: string = this.frm.value['username'];
    const password: string = this.frm.value['password'];
    if (ObjectHelper.isEmptyStr(username) || ObjectHelper.isEmptyStr(password)) {
      this.showError();
      return;
    }
    this.server.doLogin(username, password).subscribe(d => {
      this.router.navigate(['editor']);
    }, err => {
      console.log(err);
      this.showError();
    });

  }


  ngOnInit() {
  }

}
