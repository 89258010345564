import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpSentEvent,
  HttpHeaderResponse, HttpProgressEvent, HttpUserEvent
} from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError as observableThrowError } from 'rxjs';

import { Router } from '@angular/router';
import { ServerApi } from './server.service';
import { take, filter, catchError, finalize, map, tap } from 'rxjs/operators';
import { StaticInjector } from '@angular/core/src/di/injector';
import { ObjectHelper } from './helpers/object-helper';
import { resolveReflectiveProviders } from '@angular/core/src/di/reflective_provider';






@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private injector: Injector,
    private router: Router,
    private server: ServerApi
  ) {
  }

  handle401Error(error): Observable<any> {
    this.server.logOut();
    return observableThrowError(error);
  }

  handle403Error(error): Observable<any> {
    this.server.logOut();
    return observableThrowError(error);
  }


  public logoutUser() {
    return this.server.logOut();
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const authService = this.injector.get(ServerApi);
    let token: string = this.server.getToken();
    let authRequest: HttpRequest<any> = req;
    
    if (!ObjectHelper.isEmptyStr(token)) {
      console.log("ADDING TOKEN "+ token);
       authRequest = req.clone({
        headers: req.headers.set('Token', token)
      });

    }
    return next.handle(authRequest).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        // console.log("EVENT")
        // console.log(event)
        // DiagnosticsHelper.extractRequestData(authRequest);

      }
    }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              return this.handle401Error(error);
            case 403:
              return this.handle403Error(error);
            default:
              return observableThrowError(error);
          }
        } else {
          return observableThrowError(error);
        }
      }));
  }
}