import { DateHelper } from './helpers/date-helper';

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectHelper } from './helpers/object-helper';
import { environment } from 'src/environments/environment';
import { forkJoin, Observable } from 'rxjs';
import { AuthModel } from './models/auth.model';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SummaryModel } from './models/summary.model';
import { TableModel } from './models/table.model';
import { EmployeesModel } from './models/employees.model';
const loginUrl = `${environment.apiEndpoint}/login`;
const totalUrl = `${environment.apiEndpoint}/secure/total-expenses`;
const tableUrl = `${environment.apiEndpoint}/secure/list-expenses`;

const mergeUrl = `${environment.apiEndpoint}/secure/merge-expense`;
const deleteUrl = `${environment.apiEndpoint}/secure/delete-expense`;
const employeesUrl = `${environment.apiEndpoint}/secure/user-config`;



@Injectable()

export class ServerApi {
    private static readonly AUTH_TOKEN: string = 'AuthToken';
    constructor(private injector: Injector,
        private router: Router,
        private httpClient: HttpClient,
        private dateHelper: DateHelper

    ) {
    }

    public doLogin(username: string, password: string): Observable<AuthModel> {

        const params = new HttpParams()
            .set('username', username)
            .set('password', password);

        return this.httpClient.get<AuthModel>(loginUrl, {
            params: params
        }).pipe(map
            (data => {
                this.setToken(data.token);
                return data;
            }));


    }
    public getEmployees(): Observable<EmployeesModel[]> {
        return this.httpClient.get<any[]>(employeesUrl).pipe(map
            (data => {
                if (ObjectHelper.isEmptyObj(data)) {
                    data = [];
                } else {
                    (data as EmployeesModel[]).forEach((x: EmployeesModel) => {
                        x.nameFirstLetterUppercase = x.name.charAt(0).toUpperCase() + x.name.slice(1);
                    })
                }
                return data;
            }));
    }

    public getSummary(): Observable<SummaryModel> {



        const totals = this.httpClient.get<any>(totalUrl).pipe(map
            (data => {


                return data;
            }));

        return forkJoin([totals, this.getEmployees()]).pipe(map(resp => {
            let totalToFix = resp[0];
            const employees = resp[1];
            if (ObjectHelper.isEmptyObj(totalToFix)) {
                totalToFix = new SummaryModel();
            }
            totalToFix.total = 0;
            let current: EmployeesModel;
            for (let i = 0; i < employees.length; i++) {
                current = employees[i];
                totalToFix[current.name + 'Total'] = ObjectHelper.ifEmpty(totalToFix[current.name + 'Total'], 0);
                totalToFix.total += Number.parseFloat(totalToFix[current.name + 'Total']);

            }
            totalToFix.total = totalToFix.total.toFixed(2);
            return totalToFix;
        }));

    }
    public getTable(dateFrom: Date, dateUntil: Date, orderByInsertionId: boolean): Observable<TableModel[]> {
        const params = new HttpParams()
            .set('dateFrom', ObjectHelper.isEmptyObj(dateFrom) ? null : dateFrom.toISOString())
            .set('dateUntil', ObjectHelper.isEmptyObj(dateUntil) ? null : dateUntil.toISOString())
            .set('orderByInsertionId', ObjectHelper.isEmptyObj(orderByInsertionId) ? null : orderByInsertionId + '');


        return forkJoin([this.httpClient.get<TableModel[]>(tableUrl, {
            params: params
        }), this.getEmployees()]).pipe(map(resp => {

            const table: TableModel[] = resp[0];
            const employees: EmployeesModel[] = resp[1];
            let index = 1;
            if (!ObjectHelper.isEmptyList(table)) {
                table.forEach(x => {
                    x.index = index++;
                    x.clientDate = DateHelper.formatDateAsString(x.datum);
                    x.checked = (x as any).checked === 1;
                    if (!ObjectHelper.isEmptyObj(employees)) {
                        employees.forEach(emp => {
                            if (!ObjectHelper.isEmptyObj(x['suma' + emp.nameFirstLetterUppercase])) {
                                x['suma' + emp.nameFirstLetterUppercase] = Number.parseFloat(x['suma' + emp.nameFirstLetterUppercase]);
                            }

                        });
                    }
                });
            }

            return table;
        }));


    }
    public dropObject(id: number): Observable<any> {
        const params = new HttpParams()
            .set('id', id + '');

        return this.httpClient.delete<any>(deleteUrl, {
            params: params
        }).pipe(map
            (data => {

                return data;
            }));
    }
    public saveObject(model: TableModel): Observable<any> {


        return this.httpClient.post<any>(mergeUrl, {
            data: model
        }).pipe(map
            (data => {
                if (!ObjectHelper.isEmptyList(data)) {
                    data.forEach(x => {
                        x.clientDate = DateHelper.formatDateAsString(x.datum);
                    });
                }
                return data;
            }));
    }
    public getToken(): string {
        const token: string = window.localStorage.getItem(ServerApi.AUTH_TOKEN);
        return ObjectHelper.ifEmpty(token, '');
    }
    public setToken(token: string): void {
        return window.localStorage.setItem(ServerApi.AUTH_TOKEN, token);
    }

    public logOut() {
        this.setToken(null);
        this.router.navigate(['login']);
    }

    public isLoggedIn() {
        return !ObjectHelper.isEmptyStr(this.getToken());
    }



}
