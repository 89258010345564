export class TableModel {
    public index: number;
    public id: number = null;
    public datum: Date = null;
    public stavkaOpis: string = null;
    public zajednickiTrosak: number = null;
  public komentar: string = null;
    public remoteId: string = null;
    public checked: boolean = null;
    public clientDate: string = null;

}